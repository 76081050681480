body, html {
  height:100%;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
}
.active {
  border-bottom:2px solid #534fff;
}